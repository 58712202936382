import React, { useEffect, useState,useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import axios from 'axios'
import https from 'https'
import parse from 'papaparse'
import './AgGrid.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { saveAs } from 'file-saver'
import UiStats from '../../images/UiStats.svg'
import SideBar from '../SideBar/index'
import UiStatsIconGreen from '../../images/StatsGreen.png'
import UiStatsIconYellow from '../../images/StatsYellow.png'
import UiStatsIconOrange from '../../images/StatsOrange.png'
import UiStatsIconPurple from '../../images/StatsPurple.png'
import UiStatsIconBlue from '../../images/StatsBlue.png'
import UiStatsIconRose from '../../images/StatsRose.png'
import UiStatsIconRed from '../../images/StatsRed.png'
import MainTitle from '../../components/MainTitle'
import Select from 'react-select'
import {Bg,ButtonCSV,ButtonContainer,StatsIcon,Loader,Loadervalue,FilterContainer,SeqTitle,AgenciesHeader,TotalStatsContainer,PlayerTimeContainer,StatsGlobalContainer,StatsName,StatsValue, MainLayout,AgencyContainer} from './styledComponents'
import { LineChart, Line, XAxis, YAxis, BarChart, Bar,Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { forEach } from 'lodash'

const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const serverPathTgd2EditorApi = process.env.REACT_APP_TGD2API_SERVER
const tokentgd = process.env.REACT_APP_TOKENEDITOR
const instance = axios.create({
  httpsAgent: new https.Agent({ keepAlive: true, keepAliveMsecs: 6000 }),
  timeout: 60000,
})

const Stats = () => {
    const [stats, setStats] = useState([])
    const [totals, setTotals] = useState([])
    const token = useSelector((state) => state.toJS().global.agencytoken)
    const storedAgencies = useSelector((state) => state.toJS().global.agencies || [])
    const [selectedAgency, setSelectedAgency] = useState([])
    const [tgdEvalAllStats, setTgdEvalAllStats] = useState([])
    const [playerTime, setPlayerTime] = useState(0)
    const [sequencesTGD1, setSequenceIdCountsTGD1] = useState([])
    const [sequencesTGD2, setSequenceIdCountsTGD2] = useState([])
    const [selectedDate , setSelectedDate] = useState([])
    const [sequenceIdToTagMap, setSequenceIdToTagMap] = useState({});

    function generateMonthsArray(startDate, endDate) {
      const months = [];
      const start = new Date(startDate)
      const end = new Date(endDate)
    
      while (start <= end) {
        const year = start.getFullYear()
        const month = String(start.getMonth() + 1).padStart(2, '0')
        const formattedMonth = `${year}-${month}`
        months.push(formattedMonth)
        start.setMonth(start.getMonth() + 1)
      }
      return months
    }
    let mm = new Date().getMonth() + 1
    let yyyy = new Date().getFullYear()
    const startDate = '2023-01'
    const endDate = yyyy + '-' + mm
    const months = useMemo(() => generateMonthsArray(startDate, endDate), [startDate, endDate]);

    useEffect(() => {
      setStats([])
      getAgenciesStats()
      sequenceTranslator()
      // eslint-disable-next-line
    }, [selectedAgency]) 
    

    const playerTimeVal = (e) => {
      const updatedPlayerTime = parseInt(e.target.value, 0); // Convert the value to an integer
      setPlayerTime(updatedPlayerTime);
      // Call the function to update the statistics with the new playerTime value
      getTgdAllStats(storedAgencies, selectedAgency, updatedPlayerTime);
    };

    const renderPlayerTime = () => {
      return (
          <PlayerTimeContainer>
            <div style={{display:'flex',borderBottom:"10px solid #f3f4f6",width:"100%",justifyContent:'center',textAlign:'center',height:'28%'}}>Temps passé sur le simulateur par élèves</div>
              <input
                  style={{display:'flex',justifyContent:'center',textAlign:'center',height:'18%'}}
                  type="range"
                  min="0"
                  max="10000"
                  value={playerTime}
                  onChange={playerTimeVal}
              />
              <div>{playerTime}ms</div>
          </PlayerTimeContainer>
      )
  }

    const getAgenciesStats = async () => {
      try {
        const agencies = await instance.get(`${serverPathTgdApi}/agencies`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    
        const filteredAgencies = selectedAgency.length
          ? agencies.data.filter((agency) => selectedAgency.includes(agency.agencyId))
          : agencies.data
    
        await getEvalScore(filteredAgencies)
        await getTgdAllStats(filteredAgencies, selectedAgency)
        renderAgencies(filteredAgencies)
      } catch (error) {
        console.log(error)
      }
    }


    const getEvalScore = async (agencies) => {
      try {
        const agencyPromises = agencies.map(async (agency) => {
          const monthFilters = months.map(month => ({
            $and: [
              { evalScore: { $ne: null } },
              { agencyId: agency.agencyId },
              { dateCreation: { $gte: `${month}-01` } },
              { dateCreation: { $lte: `${month}-31` } }
            ]
          }))
    
          const noEvalMonthFilters = months.map(month => ({
            $and: [
              { evalScore: null },
              { agencyId: agency.agencyId },
              { dateCreation: { $gte: `${month}-01` } },
              { dateCreation: { $lte: `${month}-31` } }
            ]
          }))
    
          const [resEvalScores, resNoEvalScores] = await Promise.all([
            instance.get(`${serverPathTgdApi}/students/count`, {
              params: { filter: JSON.stringify({ $or: monthFilters }) },
              headers: { Authorization: `Bearer ${token}` },
            }),
            instance.get(`${serverPathTgdApi}/students/count`, {
              params: { filter: JSON.stringify({ $or: noEvalMonthFilters }) },
              headers: { Authorization: `Bearer ${token}` },
            }),
          ])
    
          const results = months.map((month, index) => {
            // Handle resEvalScores.data not being an array
            const studentsWithEval = Array.isArray(resEvalScores.data) ?
              resEvalScores.data.filter(data => {
                const date = new Date(data.dateCreation);
                return date.getFullYear() === parseInt(month.split('-')[0]) && date.getMonth() + 1 === parseInt(month.split('-')[1]);
              }).length : 0
    
            // Handle resNoEvalScores.data not being an array
            const studentsWithoutEval = Array.isArray(resNoEvalScores.data) ?
              resNoEvalScores.data.filter(data => {
                const date = new Date(data.dateCreation);
                return date.getFullYear() === parseInt(month.split('-')[0]) && date.getMonth() + 1 === parseInt(month.split('-')[1]);
              }).length : 0
    
            return {
              agencyName: agency.agencyName,
              month,
              studentsWithEval,
              studentsWithoutEval,
            }
          })
    
          const total = {
            total: results.reduce((acc, result) => acc + result.studentsWithEval + result.studentsWithoutEval, 0),
            totalEval: results.reduce((acc, result) => acc + result.studentsWithEval, 0),
            totalInit: results.reduce((acc, result) => acc + result.studentsWithoutEval, 0),
          }
    
          return { results, total };
        })
    
        const agencyResults = await Promise.all(agencyPromises);
    
        agencyResults.forEach(({ results, total }) => {
          setTotals(prev => [...prev, total])
          setStats(prev => [...prev, results])
        })
    
      } catch (error) {
        console.error(error.message);
      }
    }

    const sequenceTranslator = async () => {
      try {
        let SequenceFetcherArray = []
        const [SequenceFetcherFromTgd2Editor] = await Promise.all([
          axios.get(`${serverPathTgd2EditorApi}/api/sequences?access_token=${tokentgd}`)
        ])
        SequenceFetcherArray = SequenceFetcherFromTgd2Editor.data
        SequenceFetcherArray.sort()
        // Filter sequences with tags starting with "REMC:C1", "REMC:C2", or "REMC:C3"
        const remcSequences1 = SequenceFetcherArray.filter(({ tags }) => /^REMC:C1/.test(tags))
        const remcSequences2 = SequenceFetcherArray.filter(({ tags }) => /^REMC:C2/.test(tags))
        const remcSequences3 = SequenceFetcherArray.filter(({ tags }) => /^REMC:C3/.test(tags))
        const remcSequences = [...remcSequences1, ...remcSequences2, ...remcSequences3]
        let tagIndexC1 = "1.0"
        let tagIndexC2 = "2.0" 
        let tagIndexC3 = "3.0"
        const incrementIndex = (index) => {
          let [num, decimal] = index.split('.').map(Number)
          decimal += 1
          return `${num}.${decimal}`
        }
        const updatedSequences = remcSequences.map(sequence => {
          const updatedTags = sequence.tags.map(tag => {
            if (tag.startsWith("REMC:C1")) {
              const newTag = `${tagIndexC1}`
              tagIndexC1 = incrementIndex(tagIndexC1)
              return newTag
            } else if (tag.startsWith("REMC:C2")) {
              const newTag = `${tagIndexC2}`
              tagIndexC2 = incrementIndex(tagIndexC2)
              return newTag
            } else if (tag.startsWith("REMC:C3")) {
              const newTag = `${tagIndexC3}`
              tagIndexC3 = incrementIndex(tagIndexC3)
              return newTag
            } else {
              return tag
            }
          })
            return {
              ...sequence,
              tags: updatedTags
            }
          })
          const sequenceIdToTagMap = updatedSequences.reduce((acc, sequence) => {
            acc[sequence.id] = sequence.tags[0]
            return acc
          }, {})
          setSequenceIdToTagMap(sequenceIdToTagMap)
        } catch (error) {
          console.log(error)
        }
      }
        
  const getTgdAllStats = async (agencies, selectedAgency) => {
  try {
  const agencyIds = selectedAgency.length > 0 ? selectedAgency : null
  // eval stats // 
  if (!selectedAgency || selectedAgency.length === 0) {
    const [EvalTGD0, EvalTGD1, EvalTGD2, students] = await Promise.all([
      instance.get(`${serverPathTgdApi}/students?filter=${encodeURI(JSON.stringify({evalHours: null }))}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      instance.get(`${serverPathTgdApi}/evals?filter=${encodeURI(JSON.stringify({versionNumber: "TGD1" }))}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      instance.get(`${serverPathTgdApi}/evals?filter=${encodeURI(JSON.stringify({versionNumber: "TGD2" }))}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      instance.get(`${serverPathTgdApi}/students?filter=${encodeURI(JSON.stringify({playTime: { $gt: playerTime } }))}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    ]);

  const filteredStudents = students.data.filter(student => {
    return student.sequenceDoneCountDetail && 
      (
        student.sequenceDoneCountDetail.competence1 > 1 ||
        student.sequenceDoneCountDetail.competence2 > 1 ||
        student.sequenceDoneCountDetail.competence3 > 1
      )
  })
  const comp1 = filteredStudents.filter(student => student.sequenceDoneCountDetail.competence1 > 1)
  const comp2 = filteredStudents.filter(student => student.sequenceDoneCountDetail.competence2 > 1)
  const comp3 = filteredStudents.filter(student => student.sequenceDoneCountDetail.competence3 > 1)
  const studentIds = students.data.map((student) => student.studentId);
  async function batchFetchProgressions(studentIds, filterParams) {
    const batchSize = 100;
    let batchPromises = [];

    for (let i = 0; i < studentIds.length; i += batchSize) {
      const batchStudentIds = studentIds.slice(i, i + batchSize)
      const filter = {
        ...filterParams,
        studentId: { $in: batchStudentIds },
      };

      batchPromises.push(
        instance.get(`${serverPathTgdApi}/progressions?filter=${encodeURI(JSON.stringify(filter))}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      );
    }

    return Promise.all(batchPromises);
  }

  const [progressionsTGD1, progressionsTGD2] = await Promise.all([
    batchFetchProgressions(studentIds, { sequenceId: { $regex: "leg-ecf" } }),
    batchFetchProgressions(studentIds, { sequenceId: { $not: { $regex: "leg-ecf" } }, journeyId: { $not: { $regex: "leg-ecf" } } }),
  ]);

  // Function to get the first progression for each student
  function getFirstProgression(progressionsArray) {
    const firstProgressions = new Map();

    progressionsArray.forEach(response => {
      response.data.forEach(progression => {
        if (!firstProgressions.has(progression.studentId)) {
          firstProgressions.set(progression.studentId, progression);
        }
      });
    });

    return Array.from(firstProgressions.values());
  }

  const firstProgressionsTGD1 = getFirstProgression(progressionsTGD1);
  const firstProgressionsTGD2 = getFirstProgression(progressionsTGD2);

  const sequenceIdsTGD1 = Array.from(
    new Set([
      ...firstProgressionsTGD1.map(item => item.sequenceId)
    ])
  )
  const sequenceIdsTGD2 = Array.from(
    new Set([
      ...firstProgressionsTGD2.map(item => item.sequenceId)
    ])
  )

  const sequenceIdCountsTGD1 = await Promise.all(
    sequenceIdsTGD1.map(async (sequenceId) => {
      const countResponse = await instance.get(
        `${serverPathTgdApi}/progressions/count?filter=${encodeURI(JSON.stringify({ sequenceId, dateCreation: { $gte: startDate, $lte: endDate } }))}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return { sequenceId, count: countResponse.data };
    })
  );
  setSequenceIdCountsTGD1(Object.fromEntries(sequenceIdCountsTGD1.map(({ sequenceId, count }) => [sequenceId, count])));

  const sequenceIdCountsTGD2 = await Promise.all(
    sequenceIdsTGD2.map(async (sequenceId) => {
      const countResponse = await instance.get(
        `${serverPathTgdApi}/progressions/count?filter=${encodeURI(JSON.stringify({ sequenceId, dateCreation: { $gte: startDate, $lte: endDate } }))}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return { sequenceId, count: countResponse.data };
    })
  );
  setSequenceIdCountsTGD2(Object.fromEntries(sequenceIdCountsTGD2.map(({ sequenceId, count }) => [sequenceId, count])));
  
  const statsForAllAgency = months.map((month) => ({
    month,
    NOEVAL: EvalTGD0.data.filter((item) => item.dateCreation.startsWith(month)).length,
    EVALTGD1: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length,
    EVALTGD2: EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
    EVALTT: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length + EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
    ProgressionSimuTGD1: firstProgressionsTGD1?.filter((item) => item.dateCreation.startsWith(month)).length,
    ProgressionSimuTGD2: firstProgressionsTGD2?.filter((item) => item.dateCreation.startsWith(month)).length,
    ProgressionSimuTGDTT: firstProgressionsTGD1?.filter((item) => item.dateCreation.startsWith(month)).length + firstProgressionsTGD2?.filter((item) => item.dateCreation.startsWith(month)).length,
    Competence1: comp1?.filter((item) => item.dateCreation.startsWith(month)).length,
    Competence2: comp2?.filter((item) => item.dateCreation.startsWith(month)).length,
    Competence3: comp3?.filter((item) => item.dateCreation.startsWith(month)).length,
    TotalCompetence: comp1?.filter((item) => item.dateCreation.startsWith(month)).length + comp2?.filter((item) => item.dateCreation.startsWith(month)).length + comp3?.filter((item) => item.dateCreation.startsWith(month)).length,
  }))
    setTgdEvalAllStats(statsForAllAgency)
  } else {
    // using $in operator cause  MongoDB query language expects an array of values.
    const EvalTGD0 = await instance.get(
      `${serverPathTgdApi}/students?filter=${encodeURI(`{"evalHours":null${agencyIds ? `,"agencyId":{"$in": ${JSON.stringify(agencyIds)}}}` : ''}`)}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    const EvalTGD1 = await instance.get(
      `${serverPathTgdApi}/evals?filter=${encodeURI(`{"versionNumber":"TGD1"${agencyIds ? `,"agencyId":{"$in": ${JSON.stringify(agencyIds)}}}` : ''}`)}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    const EvalTGD2 = await instance.get(
      `${serverPathTgdApi}/evals?filter=${encodeURI(`{"versionNumber":"TGD2"${agencyIds ? `,"agencyId":{"$in": ${JSON.stringify(agencyIds)}}}` : ''}`)}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
  
    const students = await instance.get(
      `${serverPathTgdApi}/students?filter=${encodeURI(`{"agencyId":{"$in": ${JSON.stringify(agencyIds)}}, "playTime":{"$gt":${playerTime}}}`)}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
      const progressionsTGD1 = await instance.get(
        `${serverPathTgdApi}/progressions?filter=${encodeURI(
          JSON.stringify({
            studentId: { $in: students.data.map(student => student.studentId) },
            sequenceId: { $regex: "leg-ecf" },
            ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const filteredProgressionsTGD1 = progressionsTGD1.data.map(({ studentId, sequenceId, dateCreation }) => ({ studentId, sequenceId,dateCreation }))

    const progressionsTGD2 = await instance.get(
      `${serverPathTgdApi}/progressions?filter=${encodeURI(
        JSON.stringify({
          studentId: { $in: students.data.map(student => student.studentId) },
          sequenceId: { $not: { $regex: "leg-ecf" } },
          journeyId: { $not: { $regex: "leg-ecf" } },
          ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
        })
      )}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const filteredProgressionsTGD2 = progressionsTGD2.data.map(({ studentId, sequenceId, dateCreation }) => ({ studentId, sequenceId,dateCreation }))
    
    const sequenceIdsTGD1 = Array.from(
      new Set([
        ...filteredProgressionsTGD1.map(item => item.sequenceId),
      ])
    )
    const sequenceIdsTGD2 = Array.from(
      new Set([
        ...filteredProgressionsTGD2.map(item => item.sequenceId)
      ])
    )
      
    const sequenceIdCountsTGD1 = {};
    for (const sequenceId of sequenceIdsTGD1) {
      const countResponse = await instance.get(
        `${serverPathTgdApi}/progressions/count?filter=${encodeURI(
          JSON.stringify({
            sequenceId: { $eq: sequenceId },
            dateCreation: { $gte: startDate, $lte: endDate },
            ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      sequenceIdCountsTGD1[sequenceId] = countResponse.data;
    }
    setSequenceIdCountsTGD1(sequenceIdCountsTGD1)

    const sequenceIdCountsTGD2 = {};
    for (const sequenceId of sequenceIdsTGD2) {
      const countResponse = await instance.get(
        `${serverPathTgdApi}/progressions/count?filter=${encodeURI(
          JSON.stringify({
            sequenceId: { $eq: sequenceId },
            dateCreation: { $gte: startDate, $lte: endDate },
            ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      sequenceIdCountsTGD2[sequenceId] = countResponse.data;
    }
    setSequenceIdCountsTGD2(sequenceIdCountsTGD2)

  const statsForSelectedAgency = months.map((month) => {
  return {
    month,
    NOEVAL: EvalTGD0.data.filter((item) => item.dateCreation.startsWith(month)).length,
    EVALTGD1: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length,
    EVALTGD2: EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
    EVALTT: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length + EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
    ProgressionSimuTGD1: filteredProgressionsTGD1?.filter((item) => item.dateCreation.startsWith(month)).length,
    ProgressionSimuTGD2: filteredProgressionsTGD2?.filter((item) => item.dateCreation.startsWith(month)).length,
  }
  })
    setTgdEvalAllStats(statsForSelectedAgency)
  }
  } catch (error) {
    console.log(error)
  }
}
    const CSV = () => {
      months.forEach(() => {
        months[0] = 'Janvier'
        months[1] = 'Fevrier'
        months[2] = 'Mars'
        months[3] = 'Avril'
        months[4] = 'Mai'
        months[5] = 'Juin'
        months[6] = 'Juillet'
        months[7] = 'Aout'
        months[8] = 'Septembre'
        months[9] = 'Octobre'
        months[10] = 'Novembre'
        months[11] = 'Decembre'
      })
      
      const csvData = [
        ['',months[0],'','','',months[1],'','','',months[2],'','','',months[3],'','','',months[4],'','','',months[5],'','','',months[6],'','','',months[7],'','','',months[8],'','','',months[9],'','','',months[10],'','','',months[11],'',''],
        ['','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total',
        '','Evaluations', 'Initials', 'Total'
        ],
      ]
      stats.forEach((item) => {
        let isFirstRow = true
        let arr = []
        item.forEach((subItem) => {
          const studentEvalsCount = subItem.studentsWithoutEval + subItem.studentsWithEval
          const evalCount = subItem.studentsWithEval
          const studentsWithoutEval = subItem.studentsWithoutEval
          const agencyName = isFirstRow ? subItem.agencyName : ''
          arr.push(agencyName, evalCount, studentsWithoutEval, studentEvalsCount)
          isFirstRow = false
        })
        csvData.push(arr)
      })
    
      const csv = parse.unparse(csvData)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
      saveAs(blob, 'stats.csv')
    }
  
    const renderCSV = () => {
      return (
        <ButtonContainer>
          <ButtonCSV onClick={CSV}>Download CSV</ButtonCSV>
        </ButtonContainer>
      )
    }

    
    const renderStatsIcon = (index) => {
      const statsIcons = [UiStatsIconOrange, UiStatsIconGreen, UiStatsIconYellow,UiStatsIconBlue, UiStatsIconPurple,UiStatsIconRose,UiStatsIconRed];
      return <StatsIcon bg={statsIcons[index]} />
    }
    const totalStats = {
      NOEVAL: tgdEvalAllStats.reduce((acc, item) => acc + item.NOEVAL, 0),
      EVALTGD1: tgdEvalAllStats.reduce((acc, item) => acc + item.EVALTGD1, 0),
      EVALTGD2: tgdEvalAllStats.reduce((acc, item) => acc + item.EVALTGD2, 0),
      EVALTT: tgdEvalAllStats.reduce((acc, item) => acc + item.EVALTGD1 + item.EVALTGD2, 0),
      ProgressionSimuTGD1: tgdEvalAllStats.reduce((acc, item) => acc + item.ProgressionSimuTGD1, 0),
      ProgressionSimuTGD2: tgdEvalAllStats.reduce((acc, item) => acc + item.ProgressionSimuTGD2, 0),
      ProgressionSimuTGDTT: tgdEvalAllStats.reduce((acc, item) => acc + item.ProgressionSimuTGD1 + item.ProgressionSimuTGD2, 0),
      Competence1:  tgdEvalAllStats.reduce((acc, item) => acc + item.Competence1, 0),
      Competence2:  tgdEvalAllStats.reduce((acc, item) => acc + item.Competence2, 0),
      Competence3:  tgdEvalAllStats.reduce((acc, item) => acc + item.Competence3, 0),
      TotalCompetence:  tgdEvalAllStats.reduce((acc, item) => acc + item.Competence1 + item.Competence2 + item.Competence3, 0),
    }
    const renderTotalStatsEval = () => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',margin:'auto',marginTop:'6rem',marginLeft:'9rem'}}>
        <StatsGlobalContainer>
        <TotalStatsContainer style={{ backgroundColor: 'rgba(255, 115, 0, 0.2)'}}>
          {renderStatsIcon(0)}
            <StatsName style={{ color: '#ff7300' }}>évaluations total</StatsName>
            <StatsValue style={{ color: '#ff7300' }}>{totalStats.EVALTT}</StatsValue>
          </TotalStatsContainer>

          <TotalStatsContainer style={{ backgroundColor: 'white'}}>
          {renderStatsIcon(1)}
            <StatsName style={{ color: 'rgba(0,0,0,0.7)' }}> eval TGD 1</StatsName>
            <StatsValue style={{ color: 'black' }}>{totalStats.EVALTGD1}</StatsValue>
          </TotalStatsContainer>

          <TotalStatsContainer style={{ backgroundColor: 'white'}}>
          {renderStatsIcon(2)}
            <StatsName style={{ color: 'rgba(0,0,0,0.7)' }}>eval TGD 2</StatsName>
            <StatsValue style={{ color: 'black' }}>{totalStats.EVALTGD2}</StatsValue>
          </TotalStatsContainer>
          
          <TotalStatsContainer style={{ backgroundColor: 'white'}}>
          {renderStatsIcon(3)}
            <StatsName style={{ color: 'rgba(0,0,0,0.7)' }}>Sans eval</StatsName>
            <StatsValue style={{ color: 'black' }}>{totalStats.NOEVAL}</StatsValue>
          </TotalStatsContainer>
        </StatsGlobalContainer>
        </div>
      )
    }
    const renderTotalStatsSimu = () => {
      return(
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',margin:'auto',marginTop:'1.5rem',marginLeft:'9rem'}}>
          <StatsGlobalContainer>
            <TotalStatsContainer style={{ backgroundColor: 'rgba(117, 106, 182, 0.2)'}}>
              {renderStatsIcon(4)}
              <StatsName  style={{ color:'#756AB6'}}>progressions total</StatsName>
                <StatsValue style={{ color:'#756AB6'}}>{totalStats.ProgressionSimuTGDTT}</StatsValue>
              </TotalStatsContainer>
              <TotalStatsContainer style={{ backgroundColor: 'white'}}>
              {renderStatsIcon(5)}
              <StatsName  style={{ color:'rgba(0,0,0,0.7)'}}> progressions TGD1</StatsName>
                <StatsValue style={{ color:'black'}}>{totalStats.ProgressionSimuTGD1}</StatsValue>
              </TotalStatsContainer>
              <TotalStatsContainer style={{ backgroundColor: 'white'}}>
              {renderStatsIcon(6)}
              <StatsName  style={{ color:'rgba(0,0,0,0.7)'}}>progressions TGD2</StatsName>
                <StatsValue style={{ color:'black'}}>{totalStats.ProgressionSimuTGD2}</StatsValue>
              </TotalStatsContainer>
          </StatsGlobalContainer>
        </div>
      )
    }

    const renderTotalStatsComp = () => {
      return(
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',margin:'auto',marginLeft:'8rem'}}>
          <StatsGlobalContainer>
            <TotalStatsContainer style={{ backgroundColor: 'rgba(117, 106, 182, 0.2)'}}>
              {renderStatsIcon(3)}
              <StatsName  style={{ color:'#756AB6'}}>Compétences total</StatsName>
                <StatsValue style={{ color:'#756AB6'}}>{totalStats.TotalCompetence}</StatsValue>
              </TotalStatsContainer>
              <TotalStatsContainer style={{ backgroundColor: 'white'}}>
              {renderStatsIcon(4)}
              <StatsName  style={{ color:'rgba(0,0,0,0.7)'}}>Compétence 1</StatsName>
                <StatsValue style={{ color:'black'}}>{totalStats.Competence1}</StatsValue>
              </TotalStatsContainer>
              <TotalStatsContainer style={{ backgroundColor: 'white'}}>
              {renderStatsIcon(5)}
              <StatsName  style={{ color:'rgba(0,0,0,0.7)'}}>Compétence 2</StatsName>
                <StatsValue style={{ color:'black'}}>{totalStats.Competence2}</StatsValue>
              </TotalStatsContainer>
              <TotalStatsContainer style={{ backgroundColor: 'white'}}>
              {renderStatsIcon(6)}
              <StatsName  style={{ color:'rgba(0,0,0,0.7)'}}>Compétence 3</StatsName>
                <StatsValue style={{ color:'black'}}>{totalStats.Competence3}</StatsValue>
              </TotalStatsContainer>
          </StatsGlobalContainer>
        </div>
      )
    }
    const renderAgencies = (agencies) => {
      const options = agencies.map((agency) => ({
        value: agency.agencyId,
        label: agency.agencyName,
      }))
    
      const handleChange = (selectedOptions) => {
        setSelectedAgency(selectedOptions.map(option => option.value))
      }
      return (
        <AgencyContainer>
          <AgenciesHeader>Agences</AgenciesHeader>
          <Select
            options={options}
            isMulti
            value={options.filter(option => selectedAgency.includes(option.value))}
            onChange={handleChange}
            placeholder="Choisir une agence"
            styles={{ fontFamily: 'Poppins', marginTop: '1.5rem' }}
          />
        </AgencyContainer>
      )
    }

    const renderDateFilter = () => {
      const options = months.map((dateCreation) => ({
        value: dateCreation,
        label: dateCreation,
      }));
      const handleChange = (selectedOptions) => {
        setSelectedDate(selectedOptions ? selectedOptions.map(option => option.value) : []);
      };
      return (
        <FilterContainer>
          <Select
            onChange={handleChange}
            options={options}
            value={options.filter(option => selectedDate.includes(option.value))}
            placeholder="Choisir une date"
            isMulti
          />
        </FilterContainer>
      );
    };
    
    const renderEval = () => {
      const lineColors = ['#3E8AD1', '#82ca9d', '#ffc658', '#ff7300','#B13E4C', '#C77782', '#8884d8']
      return (
        <ResponsiveContainer width="54%" height={400} style={{ background:'white'}}>
          <div style={{fontFamily:'Poppins',fontSize:'1.7rem',textAlign:'left',paddingTop:'1.5rem',paddingLeft:'3.5rem'}}>Statistiques</div>
            <LineChart data={tgdEvalAllStats}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              {["NOEVAL", "EVALTGD1", "EVALTGD2","EVALTT","ProgressionSimuTGD1", "ProgressionSimuTGD2", "ProgressionSimuTGDTT"].map((dataKey, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={dataKey}
                  stroke={lineColors[index]}
                  activeDot={{ r: 8 }}
                />
              ))}
            </LineChart>
        </ResponsiveContainer>
      )
    }

    const renderStudentBySequencesTGD1 = () => {
      const lineColors = ['#8884d8']
      const dataForChart = Object.entries(sequencesTGD1).map(([sequenceId, count]) => ({
        sequenceId,
        count,
      }))
      return (
        <ResponsiveContainer width="31%" height={400} style={{ background:'white',marginTop: '2rem'}}>
          <div style={{fontFamily:'Poppins',fontSize:'1rem',textAlign:'left',paddingTop:'1.5rem',paddingLeft:'3.5rem'}}>Nombres d'élèves par modules TGD1</div>
          <BarChart data={dataForChart}>
            <XAxis dataKey="sequenceId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {lineColors.map((color, index) => (
              <Bar
                key={index}
                dataKey="count"
                fill={color}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )
    }

    const renderStudentBySequencesTGD2 = () => {
      const lineColors = ['#8884d8']
      const sequenceArray = Object.keys(sequenceIdToTagMap).map(sequenceId => ({
        id: sequenceId,
        tags: sequenceIdToTagMap[sequenceId]
      }))
      sequenceArray.sort((a, b) => {
        const partsA = a.tags.split('.').map(Number)
        const partsB = b.tags.split('.').map(Number)
        if (partsA[0] !== partsB[0]) {
          return partsA[0] - partsB[0];
        }
        return partsA[1] - partsB[1];
      })
      const dataForChart = sequenceArray.map(({ id, tags }) => {
        const count = sequencesTGD2[id] || 0
        return {
          sequenceId: id,
          count,
          tags
        }
      })
      return (
        <ResponsiveContainer width="31%" height={400} style={{ background: 'white', marginTop: '2rem' }}>
          <div style={{ fontFamily: 'Poppins', fontSize: '1rem', textAlign: 'left', paddingTop: '1.5rem', paddingLeft: '3.5rem' }}>
            Nombres d'élèves par modules TGD2
          </div>
          <BarChart data={dataForChart}>
            <XAxis dataKey="tags" />
            <YAxis />
            <Tooltip />
            <Legend />
            {lineColors.map((color, index) => (
              <Bar
                key={index}
                dataKey="count"
                fill={color}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )
    }
    const renderCompetences = () => {
      const lineColors = ['#756AB6', '#C77782', '#B13E4C','#3E8AD1']
      return (
        <ResponsiveContainer width="76%" height={400} style={{ background:'white',marginTop: '2rem',marginLeft:'9rem'}}>
          <div style={{fontFamily:'Poppins',fontSize:'1rem',textAlign:'left',paddingTop:'1.5rem',paddingLeft:'3.5rem'}}>Nombres de compétences par mois</div>
          <LineChart data={tgdEvalAllStats}>
            <XAxis dataKey="month"/>
            <YAxis />
            <Tooltip />
            <Legend />
            {["Competence1", "Competence2","Competence3","TotalCompetence"].map((dataKey, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={dataKey}
                stroke={lineColors[index]}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )
    }
    const GridExample = () => {
      const rowData = stats.map((item) => {
        const rowObject = {
          Agence: item[0].agencyName,
          Total: item.reduce((acc, subItem) => acc + subItem.studentsWithEval + subItem.studentsWithoutEval, 0),
        }
    
        item.forEach((subItem) => {
          const monthKey = subItem.month
          rowObject[`${monthKey}_Evaluations`] = subItem.studentsWithEval
          rowObject[`${monthKey}_Initials`] = subItem.studentsWithoutEval
        })
        return rowObject
      })
      const columnsDefs = [
        { field: 'Agence', headerName: 'Agence', minWidth: 200},
        ...months.map((month) => ({
          field: `${month}_Evaluations`,
          headerName: `${month} Evaluations`,
        })),
        ...months.map((month) => ({
          field: `${month}_Initials`,
          headerName: `${month} Initials`,
        })),
        { field: 'Total', headerName: 'Total', minWidth: 200 },
      ]
    
      return (
        <div
          className="ag-theme-alpine"
          style={{
            height: '50rem',
            width: '70rem',
            marginTop: '5rem',
            marginLeft:'0rem',
          }}
        >
          <AgGridReact rowData={rowData} columnDefs={columnsDefs} />
        </div>
      )
    }
    return (
      <Bg>
        <SideBar />
        <MainLayout>
        <MainTitle text='Statistiques' icon={UiStats}/>
        {/*stats.length < /*storedAgencies.length 1 ? 
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',marginLeft:'10%',fontSize:'2rem', flexDirection:'column'}}>
            <Loader></Loader>
              <Loadervalue><div style={{color:'#024179',paddingRight:'0.5rem'}}>{stats.length}</div> / {storedAgencies.length}</Loadervalue>
          </div> : 
          */
          <>
            <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
              {renderTotalStatsEval()} 
              {renderTotalStatsSimu()}
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',gap:'2rem',marginLeft:"-2.8rem"}}>
              {renderEval()}
              <div style={{display:'flex',flexDirection:'column',marginTop:'2rem'}}>
                {renderAgencies(storedAgencies)}
                {renderPlayerTime()}
              </div>
            </div>
            <SeqTitle>Compétences par modules</SeqTitle>
            {renderDateFilter(selectedDate)}
            <div style={{display:'flex',flexDirection:'row',marginLeft:'-9.5rem',justifyContent:'center',gap:'2rem'}}>
              {renderStudentBySequencesTGD1()}
              {renderStudentBySequencesTGD2(selectedDate)}
            </div>
            <SeqTitle>Compétences</SeqTitle>
            {renderTotalStatsComp()}
            {renderCompetences()}
            {renderCSV()}
            {/*GridExample()*/}
          </>
        }
        </MainLayout>
      </Bg>
      
    )
  }

const withConnect = connect(null, null)
const arg = compose(withConnect)(Stats)
export default arg
